import { apiKeyCheck } from "../Const/const";

export async function getDataFromScan(raw) {
  const data = JSON.stringify({
    token: "28267.g8LNf8kxnVNUmCshz",
    qrraw:
      "t=20190202T1044&s=476.00&fn=9289000100054082&i=16112&fp=399448105&n=1",
  });
  const response = await fetch("https://proverkacheka.com/api/v1/check/get", {
    method: "POST",
    body: data,
  });
  if (response.status == 200) return response.json();
  else return false;
}
//Загрузка изображения на сервер

export async function uploadImage(file) {
  const data = new FormData();

  data.append("file", file);
  const response = await fetch("https://domcash.ru/avatar.php", {
    method: "post",
    body: data,
  });
  return response.json();
}

//Проверка фескального накопителя

export async function checkFn(fn) {
  let formData = new FormData();
  formData.append("fn", fn);
  const response = await fetch("https://domcash.ru/api/qr/checkFn.php", {
    method: "post",
    body: formData,
  });
  return response.json();
}

//Добавление кэшбэка

export async function addOperation(
  sum,
  date,
  fn,
  fp,
  idUser,
  cashSumm,
  agencySumm,
  cash,
  agen,
  idShop,
  fd
) {
  let formData = new FormData();
  formData.append("fn", fn);
  formData.append("fp", fp);
  formData.append("sum", sum);
  formData.append("date", date);
  let time = new Date();
  formData.append("time", time);
  formData.append("idUser", idUser);
  formData.append("cashSumm", cashSumm);
  formData.append("agencySumm", agencySumm);
  formData.append("cash", cash);
  formData.append("agen", agen);
  formData.append("idShop", idShop);
  formData.append("fd", fd);
  const response = await fetch("https://domcash.ru/api/qr/addOperation.php", {
    method: "post",
    body: formData,
  });
  return response.json();
}

//Регистрация пользователя

export async function registration(fio, email, tel, password) {
  const formData = new FormData();
  formData.append("name", fio);
  formData.append("faceNumber", localStorage.faceNumber);
  formData.append("adress", localStorage.adress);
  formData.append("email", email);
  formData.append("tel", tel);
  formData.append("date", new Date());
  formData.append("name", fio);
  formData.append("password", password);
  const response = await fetch("https://domcash.ru/api/user/registration.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Обнволение данных пользователя

export async function updateUser(form) {
  const formData = new FormData();
  for (let key in form) {
    formData.append([key], form[key]);
  }
  const response = await fetch("https://domcash.ru/api/user/update.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Получение списка категорий

export async function getCategories(city) {
  const form = new FormData();
  form.append("city", city);
  const response = await fetch("https://domcash.ru/api/shop/category.php", {
    body: form,
    method: "POST",
  });
  return response.json();
}

//Получение списка покупок пользователя

export async function getUserSell(id) {
  let formData = new FormData();
  formData.append("id", id);
  const response = await fetch("https://domcash.ru/api/user/getUserSell.php", {
    method: "POST",
    body: formData,
  });
  return response.json();
}

//Получение списка городов

export async function getCity() {
  const response = await fetch("https://domcash.ru/api/shop/getCity.php");
  return response.json();
}

//Авторизация пользователя

export async function auth(tel, password) {
  const formData = new FormData();
  formData.append("tel", tel);
  formData.append("password", password);

  const response = await fetch("https://domcash.ru/api/user/auth.php", {
    method: "POST",
    body: formData,
  });
  return response.json();
}

//Получение списка пользователей

export async function getUsers() {
  const response = await fetch("https://domcash.ru/api/user/getUsers.php");
  return response.json();
}

//Обновить логотип партнера

export async function updateLogoPartner(path, id, name) {
  const formData = new FormData();
  formData.append("Image", "https://domcash.ru/" + path);
  formData.append("id", id);
  formData.append("Name", name);
  const response = await fetch("https://domcash.ru/api/shop/updateLogo.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Адрес Апи

export async function getAdress(str) {
  const token = "307eb1b5e51c6846adac5e5c92e1b3a5de831912";
  const response = await fetch(
    "http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    {
      method: "post",
      body: JSON.stringify({ query: str }),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + token,
      },
    }
  );
  return response.text();
}

//Авторизация админ

export async function authAdmin(login, password) {
  const formData = new FormData();
  formData.append("login", login);
  formData.append("password", password);

  const response = await fetch("https://domcash.ru/api/admin/auth.php", {
    method: "POST",
    body: formData,
  });
  return response.json();
}

//Регистрация админ

export async function registerAdmin(form) {
  const formData = new FormData();
  for (let key in form) {
    formData.append([key], form[key]);
  }

  const response = await fetch(
    "https://domcash.ru/api/admin/registration.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Добавление акции

export async function addSale(name, time, title, desc, path, logo, timeBefore) {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("timeEnd", time);
  formData.append("title", title);
  formData.append("description", desc);
  formData.append("timeBefore", timeBefore);
  formData.append("logo", logo);
  formData.append("img", "https://domcash.ru/" + path);

  const response = await fetch("https://domcash.ru/api/actions/addSale.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Изменение акции

export async function changeSale(form, logo) {
  const formData = new FormData();
  for (let key in form) {
    formData.append(key, form[key]);
  }
  formData.append("Logo", logo);
  const response = await fetch(
    "https://domcash.ru/api/actions/changeSale.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Получение списка акций

export async function getSales() {
  const response = await fetch("https://domcash.ru/api/actions/getSale.php");
  return response.json();
}

//Получение инфо об акции

export async function getSale(id) {
  let formData = new FormData();
  formData.append("id", id);
  const response = await fetch(
    "https://domcash.ru/api/actions/getSalePage.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
}

//Удаление акции

export async function deleteSale(id) {
  const formData = new FormData();
  formData.append("ID", id);
  const response = await fetch(
    "https://domcash.ru/api/actions/deleteSale.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Получение списка магазинов

export async function getShop() {
  const response = await fetch("https://domcash.ru/api/shop/getShop.php");
  return response.json();
}

//Получить информацию о магазине

export async function getShopForPage(id) {
  let formData = new FormData();
  formData.append("id", id);
  const response = await fetch(
    "https://domcash.ru/api/shop/getShopForPage.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
}

//Получить список уникальных магазинов

export async function getShopName() {
  const response = await fetch("https://domcash.ru/api/shop/shopName.php");
  return response.json();
}

//Добавить магазин

export async function addPartner(form, path) {
  const formData = new FormData();
  for (let key in form) {
    formData.append(key, form[key]);
  }
  formData.append("Image", "https://domcash.ru/" + path);
  const response = await fetch("https://domcash.ru/api/shop/addPartner.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Редактирование магазина

export async function changePartner(form) {
  const formData = new FormData();
  for (let key in form) {
    formData.append(key, form[key]);
  }

  const response = await fetch(
    "https://domcash.ru/api/shop/changePartner.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Удалить магазин

export async function deletePartner(id) {
  const formData = new FormData();

  formData.append("ID", id);

  const response = await fetch(
    "https://domcash.ru/api/shop/deletePartner.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Получение списка новостей

export async function getNews() {
  const response = await fetch("https://domcash.ru/api/news/getNews.php");
  return response.json();
}

//Получение конкретную новость

export async function getNew(id) {
  let form = new FormData();
  form.append("id", id);
  const response = await fetch("https://domcash.ru/api/news/getNewPage.php", {
    method: "POST",
    body: form,
  });
  return response.json();
}

//Добавить новость

export async function addNew(time, title, desc, img) {
  const formData = new FormData();

  formData.append("timeEnd", time);
  formData.append("image", "https://domcash.ru/" + img);
  formData.append("title", title);
  formData.append("description", desc);

  const response = await fetch("https://domcash.ru/api/news/addNew.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Добавить новость

export async function changeNew(time, title, desc, img, id) {
  const formData = new FormData();

  formData.append("timeEnd", time);
  formData.append("image", img);
  formData.append("title", title);
  formData.append("description", desc);
  formData.append("id", id);
  const response = await fetch("https://domcash.ru/api/news/changeNew.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Удалить новость

export async function deleteNew(id) {
  const formData = new FormData();

  formData.append("ID", id);

  const response = await fetch("https://domcash.ru/api/news/deleteNew.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Получение списка вопросов

export async function getQuestions() {
  const response = await fetch(
    "https://domcash.ru/api/questions/getQuestions.php"
  );
  return response.json();
}

//Удалить Вопрос

export async function deleteQuestion(id) {
  const formData = new FormData();

  formData.append("ID", id);

  const response = await fetch(
    "https://domcash.ru/api/questions/deleteQuestion.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Добавить вопрос

export async function addQuestion(title, desc) {
  const formData = new FormData();

  formData.append("title", title);
  formData.append("description", desc);

  const response = await fetch(
    "https://domcash.ru/api/questions/addQuestion.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Изменить вопрос

export async function changeQuestion(title, desc, id) {
  const formData = new FormData();

  formData.append("title", title);
  formData.append("description", desc);
  formData.append("id", id);
  const response = await fetch(
    "https://domcash.ru/api/questions/changeQuestion.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Получение списка диллеров

export async function getdillers() {
  const response = await fetch("https://domcash.ru/api/dillers/getDillers.php");
  return response.json();
}

//Получение списка диллеров

export async function activateDillers(id, act) {
  let formData = new FormData();
  formData.append("ID", id);
  formData.append("act", act);
  const response = await fetch("https://domcash.ru/api/dillers/activate.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

export async function updateAdmin(form) {
  const formData = new FormData();
  for (let key in form) {
    formData.append([key], form[key]);
  }

  const response = await fetch("https://domcash.ru/api/dillers/update.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Получение категорий

export async function getCategoriesAdmin() {
  const response = await fetch(
    "https://domcash.ru/api/categories/getCategories.php"
  );
  return response.json();
}

//Обновление категории

export async function updateCategory(name, oldName, id) {
  const formData = new FormData();
  formData.append("ID", id);
  formData.append("oldName", oldName);
  formData.append("Name", name);
  const response = await fetch(
    "https://domcash.ru/api/categories/changeCat.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Добавление категории

export async function addCategory(name) {
  const formData = new FormData();

  formData.append("Name", name);
  const response = await fetch("https://domcash.ru/api/categories/addCat.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Создание отчета

export async function createCsv(begin, end) {
  const formData = new FormData();

  formData.append("begin", begin);
  formData.append("end", end);
  const response = await fetch("https://domcash.ru/api/csv/getCsv.php", {
    method: "POST",
    body: formData,
  });
  return response.status;
}

//Получить все операции

export async function getOperations() {
  const response = await fetch("https://domcash.ru/api/csv/getOperations.php");
  return response.json();
}

export async function changeStatusOperation(id, status) {
  let form = new FormData();
  form.append("ID", id);
  form.append("status", status);
  const response = await fetch(
    "https://domcash.ru/api/qr/updateOperation.php",
    {
      method: "POST",
      body: form,
    }
  );
  return response.status;
}
