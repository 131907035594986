import { useEffect, useState } from "react";
import "./style.css";
import { Html5Qrcode } from "html5-qrcode";
import {
  addOperation,
  checkFn,
  getDataFromScan,
  getUserSell,
} from "../../Api/Api";
import { Store } from "react-notifications-component";
import { useContext } from "react";
import { UserContext } from "../../Context/user";
export default function QrScan({ setSumm, open, setOpen }) {
  const [data, setData] = useState("");
  const [user, setUser] = useContext(UserContext);
  const [tryInput, setTryInput] = useState(false);
  useEffect(() => {
    const config = { fps: 10, qrbox: { width: 200, height: 200 } };
    const html5QrCode = new Html5Qrcode("qrCodeContainer");
    const qrScanerStop = () => {
      if (html5QrCode && html5QrCode.isScanning) {
        html5QrCode
          .stop()
          .then(() => console.log("stop it"))
          .catch(() => console.log("error"));
      }
    };

    const qrCodeSuccess = (decodedText) => {
      //t=20240628T1808&s=999.00&fn=7380440700550382&i=1127&fp=2410962519&n=1

      console.log(decodedText);
      let arrParams = decodedText.split("&");
      let fn;
      let fp;
      let sum;
      let date;
      let fd;
      arrParams.map((em) => {
        if (em.includes("t=")) {
          date = em.replace("t=", "");
        }
        if (em.includes("fn=")) {
          fn = em.replace("fn=", "");
        }
        if (em.includes("fp=")) {
          fp = em.replace("fp=", "");
        }
        if (em.includes("s=")) {
          sum = em.replace("s=", "");
        }
        if (em.includes("i=")) {
          fd = em.replace("i=", "");
        }
      });

      checkFn(fn).then((status) => {
        if (status != "err") {
          let cashSumm = ((Number(sum) / 100) * status.SaleForClients).toFixed(
            2
          );
          let agencySumm = ((Number(sum) / 100) * status.AgencySumm).toFixed(2);
          addOperation(
            sum,
            date,
            fn,
            fp,
            user.ID,
            cashSumm,
            agencySumm,
            status.SaleForClients,
            status.AgencySumm,
            status.ID,
            fd
          ).then((response) => {
            if (response != "err") {
              Store.addNotification({
                title: "Успешно",
                message: `Qr отсканирован, баллы зачислены`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });

              getUserSell(user.ID).then((data) => {
                setSumm(
                  data
                    .filter((em) => em.operation.TypeOperation == "success")
                    .reduce(
                      (partialSum, em) =>
                        partialSum + Number(em.operation.CashSumm),
                      0
                    )
                );
              });
            } else {
              Store.addNotification({
                title: "Ошибка",
                message: `Qr уже отсканирован`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            }
          });
        } else {
          Store.addNotification({
            title: "Ошибка",
            message: `Чек не принадлежит к магазинам партнеров`,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__bounceIn"],
            animationOut: ["animate__animated", "animate__backOutUp"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      });
      setOpen(false);
    };
    html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccess);

    return () => {
      qrScanerStop();
    };
  }, []);
  return (
    <div className="popQr">
      <div className="someInput">
        <div
          style={tryInput ? { maxHeight: "100vh" } : { maxHeight: 0 }}
          className="inpQrForm"
        >
          <input type="text" className="inputComp" placeholder="ФН" />
          <input type="text" className="inputComp" placeholder="ФД" />
          <input type="text" className="inputComp" placeholder="ФП" />
          <input type="text" className="inputComp" placeholder="Итог" />
          <input type="date" className="inputComp" placeholder="Дата и время" />
          <input type="text" className="inputComp" placeholder="Тип операции" />
        </div>

        <button
          onClick={() => !tryInput && setTryInput(true)}
          className="inputQr"
        >
          {tryInput ? "Проверить чек" : "Ввести вручную"}
        </button>

        <button
          onClick={() => (!tryInput ? setOpen(false) : setTryInput(false))}
          className="closeQr"
        >
          {tryInput ? "Назад" : "Закрыть"}
        </button>
      </div>
      <div id="qrCodeContainer"></div>
      <p>{data}</p>
    </div>
  );
}
